<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		tooltipMsg="manulfortypesof3339"
		:showActionDelete="showDelete"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	>
		<template #header>
			{{ $t('detailofsojourn') }}
			<span v-if="model.OstypeId > 0">
				<b-link target="_blank" :href="model.Domain + model.OstypeUrlNameSk">{{ model.OstypeNameSk }}</b-link>
				<label>&nbsp;{{ $t('id') }}&nbsp;{{ model.OstypeId }}</label>
			</span>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import auth from '@/services/auth.service';
import enums from '@/services/helpers/enums';
import apiDictionary from '../../services/api/dictionary.api';
import serviceCommon from '../../services/service/common.service';

import { model, fields } from './sojourn-type.form-data';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'ObjectSojournType',
			routeParam: 'OstypeId',
			model,
			schema: {
				groups: fields,
			},
			showDelete: auth.IsAllowedForUser(enums.role.Admin),
		};
	},

	methods: {
		async loadResources() {
			var categoriesEnum = await apiDictionary.getList('ObjectSojournTypeCategory');

			this.updateSchemaFields({
				CategoryId: {
					values: categoriesEnum.data,
				},
			});
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.Id, this.$modal, this.deleteModel);
		},
	},
};
</script>
