import vueFormGenerator from 'vue-form-generator';

export const model = {
	OstypeId: 0,
	OstypeNameSk: null,
	OstypeUrlNameSk: null,
	OstypeNameCz: null,
	OstypeUrlNameCz: null,
	OstypeNamePl: null,
	OstypeUrlNamePl: null,
	OstypeNameEn: null,
	OstypeUrlNameEn: null,
	OstypeNameDe: null,
	OstypeUrlNameDe: null,
	OstypeNameHu: null,
	OstypeUrlNameHu: null,
	CategoryId: null,
	CountryList: [],
};

export const fields = [
			{
				fields: [
					{
						type: 'input',
						inputType: 'text',
						model: 'OstypeNameSk',
						maxlength: 255,
						validator: vueFormGenerator.validators.string,
						required: true,
						i18n: {
							label: 'namesk2556',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OstypeUrlNameSk',
						maxlength: 255,
						validator: vueFormGenerator.validators.string,
						required: true,
						i18n: {
							label: 'urlnamesk',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OstypeNameCz',
						maxlength: 255,
						validator: vueFormGenerator.validators.string,
						required: true,
						i18n: {
							label: 'namecz2558',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OstypeUrlNameCz',
						maxlength: 255,
						validator: vueFormGenerator.validators.string,
						required: true,
						i18n: {
							label: 'urlnamecz',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OstypeNamePl',
						maxlength: 255,
						validator: vueFormGenerator.validators.string,
						required: true,
						i18n: {
							label: 'namepl2561',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OstypeUrlNamePl',
						maxlength: 255,
						validator: vueFormGenerator.validators.string,
						required: true,
						i18n: {
							label: 'urlnamepl',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OstypeNameEn',
						maxlength: 255,
						validator: vueFormGenerator.validators.string,
						required: true,
						i18n: {
							label: 'nameen2564',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OstypeUrlNameEn',
						maxlength: 255,
						validator: vueFormGenerator.validators.string,
						required: true,
						i18n: {
							label: 'urlnameen',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OstypeNameDe',
						maxlength: 255,
						validator: vueFormGenerator.validators.string,
						required: true,
						i18n: {
							label: 'namede2562',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OstypeUrlNameDe',
						maxlength: 255,
						validator: vueFormGenerator.validators.string,
						required: true,
						i18n: {
							label: 'urlnamede',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OstypeNameHu',
						maxlength: 255,
						validator: vueFormGenerator.validators.string,
						required: true,
						i18n: {
							label: 'namehu2566',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OstypeUrlNameHu',
						maxlength: 255,
						validator: vueFormGenerator.validators.string,
						required: true,
						i18n: {
							label: 'urlnamehu',
						},
					},
					{
						type: 'select',
						model: 'CategoryId',
						validator: vueFormGenerator.validators.integer,
						values: [],
						selectOptions: {
							noneSelectedText: '',
							value: 'Id',
							name: 'Name',
						},
						styleClasses: 'half-width',
						i18n: {
							label: 'categoryinobjec',
							selectOptions: {
								noneSelectedText: 'select',
							},
						},
					},
					{
						type: 'sojourn-type-countries-table',
						label: '',
						model: 'CountryList',
						i18n: {
							label: 'countries',
						},
					},
				],
			},
		];
